interface EmptyStateProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  button?: React.ReactNode;
}
const EmptyState = ({ icon, title, description, button }: EmptyStateProps) => (
  <div className="flex h-full w-full flex-col items-center justify-center gap-[2.4rem] self-stretch">
    <div className="flex flex-col items-center justify-center gap-[1.6rem]">
      {icon}
      <h1 className="text-[2.4rem] font-semibold leading-[3.2rem]">{title}</h1>
      <span className="text-brand-dark-gray">{description}</span>
      {button}
    </div>
  </div>
);

export default EmptyState;
