import { useRouter } from 'next/navigation';
import { useState, useEffect } from 'react';

// USED FOR AUCTIONS
function useCountdown(
  startTime: number,
  endTime: number,
  countdownType: 'start' | 'end'
) {
  const [timeLeft, setTimeLeft] = useState(
    (countdownType === 'start' ? startTime : endTime) - new Date().getTime()
  );
  const router = useRouter();

  useEffect(() => {
    if (
      countdownType === 'end' &&
      endTime < new Date().getTime() &&
      timeLeft < 0
    ) {
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(Math.max(timeLeft - 1000, 0));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdownType, endTime, timeLeft]);

  useEffect(() => {
    if (timeLeft === 0) {
      router.refresh();

      if (countdownType === 'end') {
        setTimeLeft(endTime - new Date().getTime());
      } else {
        setTimeLeft(startTime - new Date().getTime());
      }
    }
  }, [timeLeft, countdownType, router, startTime, endTime]);

  const days = Math.floor(timeLeft / 1000 / 60 / 60 / 24);
  const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
  const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
  const seconds = Math.floor((timeLeft / 1000) % 60);

  return { days, hours, minutes, seconds };
}

export default useCountdown;
