'use client';

import FormCurrencyInputFancy from '@c/forms/controls/FormCurrencyInputFancy';
import FormLabel from '@c/forms/controls/FormLabel';
import BaseModal from '@c/modals/BaseModal';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@ui/Button';
import { ProductDocument } from 'models/product';
import Image from 'next/image';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import SmartPricingIcon from './assets/smart-pricing.svg';
import SafeImage from '@ui/SafeImage';
import { formatCurrency } from '@util/index';
import { useState } from 'react';
import { useToastContext } from 'context/ToastContext';
import { logError } from '@util/logError';

export default function SmartPricingModal({
  open,
  setOpen,
  product,
  updateFloorPrice,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductDocument;
  updateFloorPrice: (
    floorPrice: number | undefined,
    product: ProductDocument
  ) => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const { showSuccessToast } = useToastContext();

  const smartPricingModalSchema = z.object({
    floor_price: z
      .number({
        required_error: 'Floor price price is required',
        invalid_type_error: 'Please enter a valid price',
      })
      .gte(1, 'Floor price cannot be lower than $1')
      .refine(
        (value) => value < product.price,
        'Floor price cannot be higher than the actual product price'
      ),
  });

  type SmartPricingModalFormData = z.infer<typeof smartPricingModalSchema>;

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<SmartPricingModalFormData>({
    resolver: zodResolver(smartPricingModalSchema),
  });

  const floorPrice = watch('floor_price');

  const onConfirmSmartPricing = async () => {
    try {
      setLoading(true);
      await updateFloorPrice(floorPrice ?? 0, product);
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <BaseModal isOpen={open} dismiss={() => setOpen(false)} hideCloseIcon>
      <form
        className="flex flex-col gap-y-8"
        onSubmit={handleSubmit(onConfirmSmartPricing)}
      >
        <div className="flex items-start gap-x-4">
          <Image
            src={SmartPricingIcon}
            alt="smart pricing icon"
            width={30}
            height={30}
          />

          <div className="flex flex-col gap-y-2">
            <span className="flex items-center gap-[1.6rem] text-[1.8rem] font-medium">
              Smart Pricing
            </span>
            <span className="max-w-[45rem] pt-2 text-[1.8rem] text-brand-dark-gray">
              Smart Pricing will lower your price over time but won&apos;t ever
              go under your minimum.
            </span>
          </div>
        </div>
        <div className="flex w-full items-start gap-x-2 rounded-xl bg-brand-off-white px-[5rem] py-4">
          <SafeImage
            src={product.thumbnail}
            alt={product.title}
            width={800}
            height={800}
            className="aspect-square h-32 w-32 rounded-lg object-cover"
          />

          <div className="flex flex-col gap-y-2 pl-2">
            <h1 className="text-[1.8rem] font-medium">{product.title}</h1>

            <h3 className="text-[2rem] font-medium text-brand-secondary">
              Current Price: {formatCurrency(product.price)}
            </h3>
          </div>
        </div>
        <div className="flex w-full justify-center gap-[1.6rem] p-[1.6rem] text-center">
          <FormLabel
            value="Minimum Price"
            required
            errorMessage={errors.floor_price?.message}
          >
            <Controller
              control={control}
              name="floor_price"
              render={({ field }) => (
                <div className="flex w-full justify-center">
                  <div className="my-4 max-w-[20rem]">
                    <FormCurrencyInputFancy
                      placeholder="0.00"
                      error={!!errors?.floor_price}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      onBlur={field.onBlur}
                      value={field.value?.toString() ?? ''}
                    />
                  </div>
                </div>
              )}
            />
          </FormLabel>
        </div>

        <div className="flex w-full justify-end gap-x-2 pt-8">
          <Button
            text="Cancel"
            onClick={() => setOpen(false)}
            type="tertiary"
          />

          <Button
            text={loading ? 'Loading...' : 'Confirm'}
            type="secondary"
            buttonType="submit"
          />
        </div>
      </form>
    </BaseModal>
  );
}
