import { useQuery } from '@tanstack/react-query';
import { getMainCats } from '@util/firestore/categories';

const useMainCats = () => {
  const { data: mainCats } = useQuery({
    queryKey: ['mainCats'],
    queryFn: () => getMainCats(),
  });

  if (!mainCats) return [];
  return mainCats;
};

export default useMainCats;
