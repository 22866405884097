'use client';

import BaseModal from '@c/modals/BaseModal';
import Button from '@ui/Button';
import { ProductDocument } from 'models/product';

export default function SmartPricingOffModal({
  open,
  setOpen,
  product: product,
  updateFloorPrice,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: ProductDocument;
  updateFloorPrice: (
    floorPrice: number | undefined,
    product: ProductDocument
  ) => void;
}) {
  return (
    <BaseModal isOpen={open} dismiss={() => setOpen(false)} hideCloseIcon>
      <div className="flex h-[25rem] flex-col justify-between">
        <div className="flex flex-col gap-y-2">
          <span className="flex items-center gap-[1.6rem] text-[2.4rem] font-medium">
            Are you sure?
          </span>
          <span className="max-w-[40rem] pt-6 text-[2rem] text-brand-dark-gray">
            Are you sure you want to turn off smart pricing for this product?
          </span>
        </div>

        <div className="flex w-full justify-end gap-x-2">
          <Button
            text="Cancel"
            onClick={() => setOpen(false)}
            type="tertiary"
          />

          <Button
            text="Turn Off"
            onClick={() => {
              updateFloorPrice(undefined, product);
            }}
            type="secondary"
          />
        </div>
      </div>
    </BaseModal>
  );
}
